<template>
  <div class="dialog">
    <!-- 新建金句 -->
    <el-dialog
      title="新建金句"
      :visible.sync="dialogVisible"
      class="newBuildSoundBite"
      top="2vw"
      :before-close="dialogVisibleClose"
    >
      <div class="content">
        <div class="content-btnGroup">
          <input
            type="file"
            style="display: none"
            @change="fileChange"
            ref="file"
            accept=".xls,.xlsx"
          />
          <div class="btn-item upload" @click="uploadClick">
            <i class="btn-item-icon green"></i>
            <div class="btn-item-text">批量上传</div>
          </div>
          <div class="btn-item download" @click="downLoad">
            <i class="btn-item-icon blue"></i>
            <div class="btn-item-text">空白表格下载</div>
          </div>
        </div>
        <el-form ref="form" :rules="rules" :model="form">
          <el-form-item label="句库名称" prop="name">
            <el-select
              v-model="form.name"
              placeholder="请选择句库"
              @change="getTheme"
            >
              <el-option
                v-for="item in sentenceBankList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="newBuild" @click="openSentenceBank()">新建</div>
          </el-form-item>

          <el-form-item label="句库主题" prop="theme">
            <el-select
              v-model="form.theme"
              placeholder="请选择句库主题"
              :no-data-text="ThemeText"
            >
              <el-option
                v-for="item in themeList"
                :key="item.id"
                :label="item.content"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="newBuild" @click="openTheme">新建</div>
          </el-form-item>

          <el-form-item label="具体内容" prop="content">
            <el-input
              type="textarea"
              maxlength="800"
              show-word-limit
              v-model="form.content"
              placeholder="请填写具体内容，字数不超过800字"
            ></el-input>
          </el-form-item>

          <el-form-item label="来源">
            <el-input
              v-model="form.source"
              placeholder="选填，默认为“暂未明确来源”"
              class="input"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-radio v-model="radio" label="0">时间</el-radio>
            <el-input
              v-model="form.time"
              placeholder="选填，按照2021-01-01格式填，默认为上传时间"
              class="inputOne"
              :disabled="timeDisabled"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-radio v-model="radio" label="1">朝代</el-radio>
            <el-cascader
              :options="options"
              :props="dynastyProps"
              placeholder="选填，请选择朝代"
              :show-all-levels="false"
              v-model="form.dynasty"
              :disabled="dynastyDisabled"
            ></el-cascader>
          </el-form-item>

          <el-form-item class="footer">
            <el-button type="primary" @click="submitForm('form')"
              >保存</el-button
            >
            <el-button @click="dialogVisibleClose()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 新建句库 -->
    <el-dialog
      title="新建句库"
      class="sentenceBank"
      :visible.sync="sentenceBank"
      :before-close="sentenceBankClose"
    >
      <el-input
        type="textarea"
        placeholder="请输入句库的名称，不超过30个字符"
        v-model="textarea"
        maxlength="30"
        show-word-limit
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sentenceBankSure">确 定</el-button>
        <el-button @click="sentenceBankClose">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 新建主题 -->
    <el-dialog
      title="新建主题"
      :visible.sync="theme"
      class="theme"
      :before-close="themeClose"
    >
      <div class="content">
        <div class="content-label">
          <span>*</span>选择句库
          <el-select v-model="choiceTheme" placeholder="请选择句库">
            <el-option
              v-for="item in sentenceBankList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div class="newBuild" @click="openSentenceBank()">新建</div>
        </div>
        <div class="content-input">
          <el-input
            type="textarea"
            placeholder="请输入主题的名称，不超过30个字符"
            v-model="themeTextarea"
            maxlength="30"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="themeSure">确 定</el-button>
        <el-button @click="themeClose">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 免费金句库 -->
    <el-dialog
      title="免费金句库"
      :modal="false"
      @close="closeFreeSentencePopup"
      :visible.sync="freeSentencePopup"
      class="freeSentencePopup"
      top="2.5vh"
    >
      <div class="content">
        <div class="added">
          <div class="added-title">已添加句库</div>
          <div class="added-content">
            <div
              :title="item.name"
              class="content-item"
              v-for="item in addedList"
              :key="item.id"
              @mouseenter="enter(item)"
              @mouseleave="leave(item)"
              @click="del(item)"
            >
              <i class="content-del-icon" v-if="item.mouser == true"></i>
              <div class="content-del-text" v-if="item.mouser == true">
                删除
              </div>
              <div class="content-item-text" v-else>{{ item.conName }}</div>
            </div>
          </div>
        </div>
        <div class="canAdd">
          <div class="canAdd-title">
            可添加句库
            <div class="canAdd-subTitle">点击添加句库</div>
          </div>
          <div class="canAdd-content">
            <div
              class="canAdd-content-item"
              :title="item.name"
              v-for="item in notAddList"
              :key="item.id"
              @click="addLibrary(item)"
            >
              {{ item.conName }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addOrUpdateOftenLibrary,
  findPersonalOftenLibrary,
  loadOftenTypeByLibId,
  addOrUpdateOftenType,
  findAncientTimes,
  addOrUpdateOftenWord,
  downloadTestExcel,
  uploadOftenWord,
  added,
  notadded,
  addOftenUseLibrary,
  deleteOftenUseLibrary,
} from "api/goldenSentenceIndex.js";
export default {
  data() {
    return {
      dialogVisible: false, //新建金句
      form: {
        name: "",
        theme: "",
        content: "",
        source: "",
        time: "",
        dynasty: "",
      },
      rules: {
        name: [
          { required: true, message: "请选择句库名称", trigger: "change" },
        ],
        theme: [
          { required: true, message: "请选择句库主题", trigger: "change" },
        ],
        content: [
          { required: true, message: "请填写具体内容", trigger: "blur" },
        ],
      },
      radio: "0",
      timeDisabled: false,
      dynastyDisabled: true,
      sentenceBankList: [], //句库选项list
      themeList: [], //主题list
      sentenceBank: false, //新建句库
      textarea: "",
      theme: false, //新建主题
      themeTextarea: "",
      choiceTheme: "",
      options: [], //朝代级联数据
      dynastyProps: {
        children: "children",
        label: "title",
        value: "id",
        expandTrigger: "hover",
      },
      ThemeText: "请先选择句库名称！",
      freeSentencePopup: false, //免费金句库
      addedList: [], //已添加list
      notAddList: [], //可添加句库
    };
  },
  watch: {
    radio(cur, old) {
      if (cur == "1") {
        this.dynastyDisabled = false;
        this.timeDisabled = true;
        this.form.time = "";
      } else {
        this.timeDisabled = false;
        this.dynastyDisabled = true;
        this.form.dynasty = "";
      }
    },
  },
  methods: {
    closeFreeSentencePopup() {
      this.$emit("handleClose");
    },
    // 批量上传
    uploadClick() {
      this.$refs.file.click();
    },
    // 样表下载
    async downLoad() {
      const res = await downloadTestExcel();
      let blob = new Blob([res], {
        type: "application/octet-stream",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "常用语库.xls";
      link.click();
      //释放内存
      window.URL.revokeObjectURL(link.href);
    },
    // 上传文件触发
    fileChange(e) {
      let file = e.target.files[0];
      this.upload(file);
    },
    // 上传文件接口
    async upload(file) {
      let formData = new FormData();
      formData.append("file", file);
      const res = await uploadOftenWord(formData);
      if (res.code === 200) {
        console.log(res);
      }
    },
    // 句库列表点击事件
    tableBarListClick(item) {
      this.$parent.libStr = item.id;
      this.$parent.getFindOftenWord();
      this.$parent.getTheme();
    },
    // 新建金句关闭触发
    dialogVisibleClose() {
      this.form.name = "";
      this.form.theme = "";
      this.form.content = "";
      this.form.source = "";
      this.form.time = "";
      this.form.dynasty = "";
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
    // 打开新建句库
    openSentenceBank() {
      this.sentenceBank = true;
    },
    // 新建金句关闭触发
    sentenceBankClose() {
      this.textarea = "";
      this.sentenceBank = false;
    },
    // 打开新建句库主题
    openTheme() {
      this.theme = true;
    },
    // 句库主题关闭触发
    themeClose() {
      this.theme = false;
      this.themeTextarea = "";
    },
    // 新建句库确定
    async sentenceBankSure() {
      if (this.textarea == "") {
        this.$message({
          type: "error",
          message: "请输入名称！",
        });
      }
      let data = {
        name: this.textarea,
        libType: 3,
      };
      const res = await addOrUpdateOftenLibrary(data);
      if (res.code === 200) {
        this.$parent.successTips = true;
        this.$parent.sucessTipsText = "新建句库成功！";
        setTimeout(() => {
          this.$parent.successTips = false;
        }, 2000);
        this.getPersonalOftenLibrary();
        this.sentenceBank = false;
        this.sentenceBankClose();
      } else {
        this.$parent.errorTips = true;
        this.$parent.errorTipsText = res.message;
        setTimeout(() => {
          this.$parent.successTips = false;
        }, 2000);
      }
    },
    // 获取句库select
    async getPersonalOftenLibrary() {
      const res = await findPersonalOftenLibrary();
      if (res.code === 200) {
        this.sentenceBankList = res.data;
      } else if (res.code === 2001) {
        this.sentenceBankList = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 获取句库主题
    async getTheme() {
      let formData = new FormData();
      formData.append("libId", this.form.name);
      const res = await loadOftenTypeByLibId(formData);
      if (res.code === 200) {
        this.themeList = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
        this.ThemeText = "无数据!";
      }
    },
    // 新建主题确定
    async themeSure() {
      let data = {
        content: this.themeTextarea,
        libId: this.choiceTheme,
        type: 3,
      };
      const res = await addOrUpdateOftenType(data);
      if (res.code === 200) {
        this.$parent.successTips = true;
        this.$parent.sucessTipsText = "新建主题成功！";
        setTimeout(() => {
          this.$parent.successTips = false;
        }, 2000);
        this.getPersonalOftenLibrary();
        this.getTheme();
        this.theme = false;
        this.themeClose();
      } else {
        this.$parent.errorTips = true;
        this.$parent.errorTipsText = res.message;
        setTimeout(() => {
          this.$parent.successTips = false;
        }, 2000);
      }
    },
    // 获取朝代
    async getDynasty() {
      const res = await findAncientTimes();
      if (res.code === 200) {
        this.options.push(res.data);
        this.adjustArrFormat(this.options);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 调整数组格式
    adjustArrFormat(list) {
      list.map((item) => {
        if (item.children.length <= 0) {
          this.$delete(item, "children");
        } else {
          this.adjustArrFormat(item.children);
        }
      });
    },
    // 新建金句
    async newPreservation() {
      let formData = new FormData();
      formData.append("libId", this.form.name);
      formData.append("oftenType", this.form.theme);
      formData.append("oftenWord", this.form.content);
      formData.append("soure", this.form.source);
      formData.append("wordTime", this.form.time);
      formData.append("type", 3);
      formData.append("cll_date", this.radio);
      formData.append("ancientId", this.form.dynasty.pop());
      const res = await addOrUpdateOftenWord(formData);
      if (res.code === 200) {
        this.$parent.successTips = true;
        this.$parent.sucessTipsText = "新建金句成功！";
        setTimeout(() => {
          this.$parent.successTips = false;
        }, 2000);
        this.$parent.getFindOftenWord();
        this.$parent.getTheme();
        this.dialogVisibleClose();
      } else {
        this.$parent.errorTips = true;
        this.$parent.errorTipsText = res.message;
        setTimeout(() => {
          this.$parent.errorTips = false;
        }, 2000);
      }
    },
    // 新建金句验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.newPreservation();
        } else {
          return false;
        }
      });
    },
    // 获取已添加句库list
    async getaddedList() {
      const params = {
        groupId: 2,
      };
      const res = await added(params);
      if (res.code === 200) {
        this.addedList = res.data;
        this.addedList.map((item) => {
          item.mouser = false;
        });
      } else if (res.code === 2001) {
        this.addedList = res.data;
      }
    },
    // 获取可添加句库
    async getNotaddedList() {
      const params = {
        groupId: 2,
      };
      const res = await notadded(params);
      if (res.code === 200) {
        this.notAddList = res.data;
      }
    },
    // 添加句库
    async addLibrary(item) {
      let formData = new FormData();
      formData.append("id", item.id);
      const res = await addOftenUseLibrary(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "添加句库成功！",
        });
        this.getaddedList();
        this.getNotaddedList();
      }
    },
    // 鼠标移入事件
    enter(item) {
      item.mouser = true;
      this.$forceUpdate();
    },
    // 鼠标移出事件
    leave(item) {
      item.mouser = false;
      this.$forceUpdate();
    },
    // 删除
    async del(item) {
      let formData = new FormData();
      formData.append("id", item.id);
      const res = await deleteOftenUseLibrary(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        this.getaddedList();
        this.getNotaddedList();
      }
    },
    // 打开免费金句库弹窗
    openfreeSentencePopup: function () {
      this.freeSentencePopup = true;
      console.log("打开新建金句弹窗");
      this.getaddedList();
      this.getNotaddedList();
    },
    // 打开新建金句弹窗
    openAddSentence: function () {
      this.dialogVisible = true;
      console.log("打开免费金句库弹窗");
      this.getPersonalOftenLibrary();
      this.getDynasty();
    },
  },
  created() {
    this.getPersonalOftenLibrary();
    this.getDynasty();
    this.getaddedList();
    this.getNotaddedList();
  },
  mounted: function () {
    window.openAddSentence = this.openAddSentence;
    window.openfreeSentencePopup = this.openfreeSentencePopup;
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  // 新建金句弹窗
  .newBuildSoundBite {
    ::v-deep .el-dialog {
      width: 610px;
      height: 620px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 13px;
          right: 15px;
        }
      }
      .el-dialog__body {
        padding: 20px 30px 0;
        .content {
          .content-btnGroup {
            display: flex;
            .btn-item {
              width: 112px;
              height: 36px;
              background: #ffffff;
              border: 1px solid #4587ff;
              border-radius: 18px;
              margin-right: 11px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              .btn-item-icon {
                width: 19px;
                height: 21px;
                margin-right: 10px;
              }
              .blue {
                @include backgroundGroup(
                  "../../assets/images/goldenSentence/exe-blue.png"
                );
              }
              .green {
                @include backgroundGroup(
                  "../../assets/images/goldenSentence/exe-green.png"
                );
              }
            }
            .upload {
              border: 1px solid #43ac4f;
              color: #43ac4f;
            }
            .download {
              width: 141px;
              height: 36px;
              background: #ffffff;
              border: 1px solid #4587ff;
              border-radius: 18px;
              color: #4587ff;
            }
          }
          .el-form {
            margin-top: 20px;
            .el-form-item {
              .el-form-item__label {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
              }
              .el-select {
                width: 397px;
              }
              .newBuild {
                width: 72px;
                height: 40px;
                background: #4c8cff;
                border: 1px solid #4587ff;
                border-radius: 0px 3px 3px 0px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #ffffff;
                cursor: pointer;
                transform: translateY(1px);
                text-align: center;
              }
              .el-textarea {
                width: 469px;
                height: 100px;
                background: #ffffff;
                border: 1px solid #cecece;
                border-radius: 5px;
                .el-textarea__inner {
                  height: 99px;
                  border: none;
                  resize: none;
                }
              }
              .input {
                width: 472px;
                margin-left: 38px;
              }
              .inputOne {
                width: 472px;
              }
              .el-form-item__content {
                display: flex;
                align-items: center;
                .el-radio {
                  margin-right: 25px;
                }
                .el-cascader {
                  width: 472px;
                }
              }
            }
            .footer {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  // 新建句库弹窗
  .sentenceBank {
    ::v-deep .el-dialog {
      width: 428px;
      height: 265px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 13px;
          right: 15px;
        }
      }
      .el-dialog__body {
        padding: 30px 32px 30px 30px;
        .el-textarea {
          height: 102px;
          background: #ffffff;
          border: 1px solid #cecece;
          border-radius: 5px;
          .el-textarea__inner {
            height: 101px;
            border: none;
            resize: none;
          }
        }
      }
    }
  }
  // 新建主题弹窗
  .theme {
    ::v-deep .el-dialog {
      width: 428px;
      height: 335px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 13px;
          right: 15px;
        }
      }
      .el-dialog__body {
        padding: 20px 32px 30px 30px;
        .content {
          .content-label {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            cursor: pointer;
            span {
              color: #eb441e;
            }
            .content-icon {
              width: 16px;
              height: 16px;
              background-image: url("../../assets/images/goldenSentence/themeIcon.png");
              background-size: cover;
              background-repeat: no-repeat;
              margin-left: 10px;
            }
            .el-select {
              margin-left: 15px;
            }
            .newBuild {
              width: 60px;
              height: 40px;
              background: #4c8cff;
              border: 1px solid #4587ff;
              border-radius: 0px 3px 3px 0px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 40px;
              cursor: pointer;
              transform: translateY(1px);
              text-align: center;
            }
          }
          .content-input {
            margin-top: 20px;
            .el-textarea {
              height: 102px;
              background: #ffffff;
              border: 1px solid #cecece;
              border-radius: 5px;
              .el-textarea__inner {
                height: 101px;
                border: none;
                resize: none;
              }
            }
          }
        }
      }
    }
  }
  // 免费金句库
  .freeSentencePopup {
    ::v-deep .el-dialog {
      width: 500px;
      height: 600px;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.24);
      .el-dialog__header {
        padding: 0;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-dialog__title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .el-dialog__headerbtn {
          top: 8px;
          right: 11px;
          font-size: 18px;
        }
      }
      .el-dialog__body {
        padding: 12px 0 0;
        margin: 0px 10px;
        border-top: 1px solid #f7f7f7;
        height: 90%;
        overflow-y: auto;
        .content {
          .added {
            padding-bottom: 18px;
            border-bottom: 1px solid #f7f7f7;
            .added-title {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
            }
            .added-content {
              margin-top: 19px;
              display: flex;
              flex-wrap: wrap;
              .content-item {
                width: 100px;
                margin-right: 6px;
                margin-bottom: 10px;
                height: 30px;
                background: #f3f3f3;
                border-radius: 5px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                .content-del-icon {
                  width: 11px;
                  height: 12px;
                  @include backgroundGroup(
                    "../../assets/images/goldenSentence/del-icon.png"
                  );
                  margin-right: 4px;
                }
                .content-del-text {
                  font-size: 13px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #4587ff;
                }
                .content-item-text {
                  padding: 0 10px;
                  @include ellipsis();
                }
              }
            }
          }
          .canAdd {
            margin-top: 18px;
            .canAdd-title {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
              display: flex;
              align-items: center;
              .canAdd-subTitle {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-left: 9px;
              }
            }
            .canAdd-content {
              margin-top: 19px;
              display: flex;
              flex-wrap: wrap;
              .canAdd-content-item {
                width: 100px;
                margin-right: 6px;
                margin-bottom: 10px;
                height: 30px;
                background: #f3f3f3;
                border-radius: 5px;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
                @include ellipsis();
                padding: 0 10px;
                &:hover {
                  background: #f4f8ff;
                  color: #4587ff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.el-popper .el-cascader-panel .el-cascader-menu {
  min-width: 100px !important;
}
</style>
